<template>
  <div class="page-cu-container">
    <div class="page-cu-top" ref="searchbox">
      <a-form layout="inline">
        <a-form-item>
          <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 300px"
            v-model="page.project_id"
            placeholder="请选择项目"
            show-search
            :filter-option="filterOption"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in proJson"
              :key="index"
              :value="item.id"
              :title="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 120px"
            v-model="page.work_id"
            placeholder="请选择工种"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in jobJson"
              showSearch
              :key="index"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            v-model="page.subject_state"
            placeholder="请选择答题状态"
            style="width: 150px"
          >
            <a-select-option value="">请选择答题状态</a-select-option>
            <a-select-option value="1">未通过</a-select-option>
            <a-select-option value="2">已通过</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            v-model="page.contract_state"
            placeholder="请选择合同状态"
            style="width: 150px"
          >
            <a-select-option value="">请选择合同状态</a-select-option>
            <a-select-option value="1">待签合同</a-select-option>
            <a-select-option value="2">合同终止</a-select-option>
            <a-select-option value="3">合同正常</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            v-model="page.video_learn_state"
            placeholder="请选择视频观看状态"
            style="width: 180px"
          >
            <a-select-option value="">请选择视频观看状态</a-select-option>
            <a-select-option value="1">未观看完</a-select-option>
            <a-select-option value="2">已观看完</a-select-option>
          </a-select>
        </a-form-item>
        <!-- <a-form-item>
          <a-select
            v-model="page.state"
            placeholder="请选择入场状态"
            style="width: 150px"
          >
            <a-select-option value="">请选择入场状态</a-select-option>
            <a-select-option value="1">待审核</a-select-option>
            <a-select-option value="2">入厂审核中</a-select-option>
            <a-select-option value="3">入厂已通过</a-select-option>
            <a-select-option value="4">入厂已拒绝</a-select-option>
            <a-select-option value="5">出厂审核中</a-select-option>
            <a-select-option value="6">出厂已通过</a-select-option>
            <a-select-option value="7">出厂已拒绝</a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item>
          <a-button type="primary" @click="searchList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
<!--        <a-form-item>-->
<!--          <a-button-->
<!--            :icon="getExicon.icon"-->
<!--            :disabled="getExicon.ed"-->
<!--            @click="$exportFun('/export/userState', page)"-->
<!--            >导出</a-button-->
<!--          >-->
<!--        </a-form-item>-->
        <!-- <a-form-item>
          <a-button type="default" @click="addAction" icon="plus-circle"
            >新增</a-button
          >
        </a-form-item> -->
      </a-form>
    </div>
    <div class="page-cu-main">
      <a-table
        :columns="columns"
        :data-source="list"
        rowKey="id"
        bordered
        :pagination="false"
        :scroll="{ y: tableHeigt }"
        :show-total="(total, range) => `总数：${total} 条`"
      >
        <template slot="subject_state" slot-scope="text">
          <a-tag color="red" v-if="text == '1'">未通过</a-tag>
          <a-tag color="green" v-if="text == '2'">已通过</a-tag>
        </template>
        <template slot="contract_state" slot-scope="text">
          <a-tag color="orange" v-if="text == '1'">待签合同</a-tag>
          <a-tag color="red" v-if="text == '2'">合同终止</a-tag>
          <a-tag color="green" v-if="text == '3'">合同正常</a-tag>
        </template>
        <template slot="video_learn_state" slot-scope="text">
          <a-tag color="orange" v-if="text == '1'">未完成</a-tag>
          <a-tag color="green" v-if="text == '2'">已完成</a-tag>
        </template>
        <template slot="state" slot-scope="text">
          <a-tag color="orange" v-if="text == '1'">待审核</a-tag>
          <a-tag color="orange" v-if="text == '2'">入厂审核中</a-tag>
          <a-tag color="green" v-if="text == '3'">入厂已通过</a-tag>
          <a-tag color="red" v-if="text == '4'">入厂已拒绝</a-tag>
          <a-tag color="orange" v-if="text == '5'">出厂审核中</a-tag>
          <a-tag color="green" v-if="text == '6'">出厂已通过</a-tag>
          <a-tag color="red" v-if="text == '7'">出厂已拒绝</a-tag>
        </template>
        <template slot="idno_positive_img_url" slot-scope="text">
         <view-file :data="text"></view-file>
        </template>
        <template slot="bank_card" slot-scope="text, record">
          <div v-if="record.bank_card != null && record.bank_card != ''">
            {{ record.bank_provinces }},{{ record.bank_name }},{{
              record.bank_card
            }}
          </div>
          <div v-else>暂无信息</div>
        </template>
      </a-table>
      <div class="page-cu-pagination">
        <a-pagination
          :show-total="(total, range) => `总数：${total} 条`"
          :page-size.sync="page.pageSize"
          :total="page.totalRow"
          v-model="page.start"
          @change="changePage"
        />
      </div>
    </div>
    <a-modal
      v-model="imgvisible"
      title="预览"
      ok-text="确认"
      @ok="hideModal"
      :footer="null"
    >
      <img
        style="width: 100%"
        :src="viewImgData + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
        alt=""
      />
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      tableHeigt: 0,
      imgvisible: false,
      viewImgData: "",
      isAdd: true, // TODO: 是否是新增操作
      columns: [
        {
          title: "序号",
          align: "center",
          width: 70,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "项目名称",
          dataIndex: "pname",
          align: "center",
        },
        {
          title: "姓名",
          dataIndex: "name",
          width: 120,
        },

        {
          title: "工种",
          dataIndex: "wname",
          width: 100,
          align: "center",
        },
        // {
        //   title: "民族",
        //   dataIndex: "national",
        //   width: 70,
        //   align: "center",
        // },
        // {
        //   title: "籍贯",
        //   dataIndex: "census_register_seat",
        //   width: 270,
        // },
        // {
        //   title: "手机号",
        //   dataIndex: "mobile",
        //   width: 140,
        // },
        // {
        //   title: "身份证号",
        //   dataIndex: "idno",
        //   width: 200,
        // },
        {
          title: "身份证正面",
          dataIndex: "idno_positive_img_url",
          key: "idno_positive_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          align: "center",
          width: 120,
        },
        {
          title: "身份证反面",
          dataIndex: "idno_reverse_img_url",
          key: "idno_reverse_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          align: "center",
          width: 120,
        },
        {
          title: "生活照",
          dataIndex: "head_img_url",
          key: "head_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          align: "center",
          width: 120,
        },
        // {
        //   title: "应急联系人",
        //   dataIndex: "contact_name",
        //   width: 120,
        // },
        // {
        //   title: "应急联系人手机号码",
        //   dataIndex: "contact_mobile",
        //   width: 160,
        // },

        // {
        //   title: "状态",
        //   dataIndex: "state",
        //   scopedSlots: { customRender: "state" },
        //   align: "center",
        //   width: 70,
        // },

        // {
        //   title: "备注",
        //   dataIndex: "remark",
        //   width: 120,
        // },
        {
          title: "身份证号",
          dataIndex: "idno",
        },
        {
          title: "民族",
          dataIndex: "national",
          align: "center",
          width: 70,
        },
        {
          title: "劳务公司审核时间",
          dataIndex: "company_audit_time",
        },
          {
          title: "入场状态",
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
          width: 120,
          align: "center",
        },
        {
          title: "视频观看状态",
          dataIndex: "video_learn_state",
          scopedSlots: { customRender: "video_learn_state" },
          width: 120,
          align: "center",
        },
        {
          title: "答题状态",
          dataIndex: "subject_state",
          scopedSlots: { customRender: "subject_state" },
          width: 120,
          align: "center",
        },
        {
          title: "合同状态",
          dataIndex: "contract_state",
          scopedSlots: { customRender: "contract_state" },
          width: 120,
          align: "center",
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        key: "",
        project_id: "",
        start_time: undefined,
        end_time: undefined,
        work_id: "",
        subject_state: "",
        contract_state: "",
        video_learn_state: ""
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
      jobJson: [], // 工种下拉数据
      proJson: [], // 项目下拉数据
    };
  },
  computed: {
    getExicon() {
      return this.$store.state.EXICON;
    },
  },
  mounted() {
    this.getList();
    this.getWorkJson();
    this.getProJson();
    this.$nextTick(function () {
      this.tableHeigt =
        document.body.clientHeight - this.$refs.searchbox.offsetHeight - 170;
    });
  },
  methods: {
    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    // 获取工种下拉数据
    async getWorkJson() {
      let res = await Api.getWorkJson({ state: "1" });
      this.jobJson = res.list;
    },
    async getProJson() {
      let res = await Api.getProJson({ limit: 100000, state: "1" });
      this.proJson = res.page.list;
    },
  },
};
</script>

<style scoped>
</style>

